@use "styles/global" as global;

:root {
  --bs-modal-bg: #fff;
  --bs-modal-border-width: 1px;
  --bs-modal-border-color: #888;
  --bs-modal-border-radius: 5px;

  --bs-nav-tabs-link-active-color: global.$gk-green;

  --bs-font-sans-serif: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

body {
  // GK was originally built with Bootstrap 3 and default 14px font
  --bs-body-font-size: 14px;
  font-size: var(--bs-body-font-size);
}


.accordion {
  .accordion-button {
    font-size: 0.9rem;
    padding: .5rem;

    &.collapsed {
      background-color: global.$gk-inactive-bg !important;
      border-color: global.$gk-grey !important;
      box-shadow: 0 0 0 2px #ced4da;
    }
  }

  .accordion-button:not(.collapsed) {
    color: global.$gk-green;
    //background-color: var(--bs-success-bg-subtle);
    background-color: #F5F5F5;
  }

  .accordion-button:focus,
  .accordion-button:focus-visible {
    color: global.$gk-green;
    box-shadow: 0 0 0 2px #ced4da;
  }

  #drawnPanel .accordion-body {
    padding: 10px 5px 10px 8px;
  }

  #backgroundsPanel .accordion-body {
    padding: 10px;
  }

  #layersPanel .accordion-body {
    padding-inline: .5rem;
  }

  #otherLayersPanel .accordion-body {
    padding-inline: 1rem 3px;
  }

  #savedPanel .accordion-body {
    padding: 10px 5px 10px 3px;
  }
}

.btn {
  &.btn-secondary {
    background-color: #e0e0e0;
    border-color: #aaa;
    color: global.$gk-lighter-black;
  }
}

.modal-dialog {
  .modal-footer {
    border-top: none;
  }
}

.nav-tabs .nav-link {
  background-color: global.$gk-inactive-bg;
  border-color: global.$gk-main-color;
  color: global.$gk-lighter-black;

  &:hover {
    background-color: global.$gk-secondary-bg;
  }

  &.active {
    --bs-nav-tabs-link-active-border-color: global.$gk-main-color;
    --bs-nav-tabs-link-active-bg: global.$gk-light-grey;
    border-color: global.$gk-grey;
    border-bottom: none;
    color: global.$gk-green;
  }
}

tabset.feature-info .tab-content {
  overflow-y: unset !important;
}
