/* Bootstrap */
@import 'bootstrap';
/* Bootstrap Icons */
@import 'bootstrap-icons';

/* Import GK app styles */
@import 'styles/global.scss';
@import 'styles/elements.scss';
@import 'styles/bootstrap-overrides';
@import 'styles/button.scss';
@import 'styles/form.scss';
@import 'styles/loader.scss';
@import 'app/zoom-slider/zoom-slider.component.scss';
