label {
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
}

.form-group {
  margin-block-end: 1em;
}

.ng-valid[required], .ng-valid.required  {
  border-left: 5px solid;
  border-color: $gk-light-green;
}

.form-control.ng-invalid.ng-dirty:not(:focus):not(:active) {
  border-left: 5px solid;
  border-color: $gk-red;
}

.form-control:focus {
  border-color: $gk-blue;
}

.form-inline > div.radio:not(:last-child) {
  margin-right: .5em;
}


/* Typeahead */
.dropdown-item {
  --bs-accordion-active-bg: #337ab7;
  --bs-dropdown-item-padding-x: 12px;
  --bs-dropdown-item-padding-y: 7px;

  align-items: center;
  background-color: transparent;
  border: 0;
  clear: both;
  color: var(--bs-dropdown-link-color);
  display: flex;
  flex-flow: row nowrap;
  font-weight: 400;
  gap: .25rem;
  justify-content: flex-start;
  padding: var(--bs-dropdown-item-padding-y, 7px) var(--bs-dropdown-item-padding-x, 12px);
  text-align: left;
  text-decoration: none;
  white-space: nowrap;
  width: 100%;

  > * {
    align-self: flex-start;
    white-space: break-spaces;
    word-break: break-word;
  }

  > .id {
    color: #666;
    min-width: 5ch;
  }
  > .name {
    color: #222;
  }

  &:hover,
  &.active {
    background-color: var(--bs-accordion-active-bg, #337ab7);
    color: white;
    text-decoration: none;
  }

  &.active > .id {
    color: #ededed;
  }

  &.active > .name {
    color: #fff;
  }
}
