$control-btn-size: 44;
$control-btn-space: 7;
$control-btn-next: $control-btn-size + $control-btn-space;
$control-btns-top: $control-btn-space;
$control-btns-right: $control-btn-space;
$control-btn-small-size: 18;
$zoomslider-right: 20;
$zoomslider-height: 150;
$zoombar-height: $zoomslider-height + 2 * ($control-btn-small-size - 1);
$touch-zoombar-height: $control-btn-size + $control-btn-next;

.ol-zoom {
  position: absolute;
  margin-top: 44px; /* Make room for geolocation button */
  margin-left: auto;
  //margin-right: 3px;
  right: 5px;
  width: 46px;
}

div > .ol-zoom {
  background-color: transparent;
  &:focus {
    background-color: transparent;
  }
  &:hover {
    background-color: transparent;
  }
}

div > button.ol-zoom-in,
div button.ol-zoom-out {
  width: 44px;
  height: 44px;
  background-color: transparent;
  background-image: url('img/zoomIn.png');
  opacity: 0.8;
  &::-moz-focus-inner {
    // Mozilla Firefox
    border: 0;
  }
  &:hover {
    background-color: transparent;
    @media (min-width: 768px) {
      // Desktop
      opacity: 1;
    }
  }
  &:focus {
    background-color: transparent;
    outline-style: none;
  }
}

div > button.ol-zoom-out {
  background-image: url('img/zoomOut.png');
}
