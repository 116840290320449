/* You can add global variables here and import this file when needed */
:root {
  --bs-focus-ring-width: 2px;

  .hidden-mobile {
    @media(max-width: 767px) {
      display: none !important;
    }
  }
}


// Extra small screen / phone
$screen-xs:                  480px;
$screen-phone:               $screen-xs;

// Small screen / tablet
$screen-sm:                  768px;
$screen-tablet:              $screen-sm;

// Medium screen / desktop
$screen-md:                  992px;
$screen-desktop:             $screen-md;

// Large screen / wide desktop
$screen-lg:                  1200px;
$screen-lg-desktop:          $screen-lg;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm - 1);
$screen-sm-max:              ($screen-md - 1);
$screen-md-max:              ($screen-lg - 1);

$header-height: 50px;
$control-btn-size: 44;
$control-btn-space: 7;
$control-btn-next: $control-btn-size + $control-btn-space;
$control-btns-top: $control-btn-space;

// colors
$gk-white: rgb(255, 255, 255, 1);
$gk-secondary-bg: rgb(245, 245, 245, 1);
$gk-tertiary-bg: rgb(235, 235, 235, 1);
$gk-inactive-bg: rgb(224, 224, 224);
$gk-main-color: rgb(210, 210, 210, 1);
$gk-light-grey: rgba(204, 204, 204, 1);
$gk-grey: rgba(179, 179, 179, 1);
$gk-grey-mid: rgba(110, 110, 110, 1);
$gk-lighter-black: rgba(51, 51, 51, 1);
$gk-black: rgb(0, 0, 0, 1);
$gk-green: rgb(0, 87, 77, 1);
$gk-light-green: rgba(85, 142, 135, 1);
$gk-dark-green: rgba(41, 66, 79, 1);
$gk-orange: rgba(233, 78, 15, 0.6);
$gk-red: rgb(181, 5, 5);
$gk-yellow: rgba(255, 230, 121, 1);
$gk-dark-yellow: rgba(252, 214, 47, 1);
$gk-blue: rgb(102, 175, 233);

$gk-hover-button: $gk-grey;
$gk-green-focus: $gk-light-green;
$gk-tabs-font-color: $gk-black;
$gk-green-text: $gk-green;

// margins
$layer-list-header-left: 6px;
$layer-list-left: 1px;

// font size
$font-size: 90%;
$font-moz-adjust: 0.51;
$font-size-base: $font-size; // bootstrap elements
$line-height: 1.2;
$layer-list-size: 90%;
$layer-list-moz-adjust: 0.53;
$text-color: $gk-lighter-black;

//border
$border-color: $gk-light-grey;

// Variabler for å definere maks bredde på cellene i tabellen lagrede elementer og ikke lagrede
$checkbox_width: 15px;
$tool_width: 17px;
$zoom_width: 12px;



.cdk-overlay-container {
  z-index: 8000 !important;
}

/* Make Openlayers default Zoom control match focus and hover styles as rest of app */
button.ol-zoom-in:hover,
button.ol-zoom-out:hover {
  opacity: 1;
  outline: none !important;
}
button.ol-zoom-in:focus,
button.ol-zoom-out:focus,
button.ol-zoom-in:focus-visible,
button.ol-zoom-out:focus-visible, {
  text-decoration: none;
  outline: 1px solid #333 !important;
}


.info-icon-hidden-mobile {
  display: none;
  @media (min-width: 768px) and (orientation: portrait),(min-width: 813px) and (orientation: landscape)  {
    display: block;
  }
}

.table-hover tbody tr.indicateArea:hover{
  background-color: $gk-orange;
}

.tooltip-readded {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  z-index: 20;
}
.tooltip-static-readded {
  background-color: #FFFFFF;
  color: black;
  border: 1px solid white;
  z-index: 20;
}
.tooltip-static-readded:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}
.tooltip-static-readded:before {
  border-top-color: #FFFFFF;
}

.left-panel {
  .body-text {
    font-size: 13px;
  }

  /* Overload ngx-treeview */
  .treeview-container {
    min-height: 30px;
    overflow-x: hidden !important;
    padding: 5px 0 !important;
  }
}


/* Measure */
.tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  z-index: 20;
}

.tooltip-measure {
  opacity: 1;
  font-weight: bold;
  z-index: 20;
}

.tooltip-static {
  background-color: #ffffff;
  color: black;
  border: 1px solid white;
  z-index: 20;
}

.tooltip-measure:before,
.tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: '';
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}

.tooltip-static:before {
  border-top-color: #ffffff;
}

.hidden-mobile {
  @media (max-width: 767px) {
    display: none;
  }
}
