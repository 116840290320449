.btn:disabled, .btn.disabled, .btn[aria-disabled], .btn[disabled] {
  cursor: not-allowed;
  pointer-events: visible !important;
}

.btn:focus,
.btn:focus-visible {
  outline: var(--bs-focus-ring-color) solid var(--bs-focus-ring-width);
}

/*** .btn-gk-primary ***/
.btn-gk-primary {
  color: $gk-dark-green;
  background-color: $gk-yellow;
  border-color: $gk-yellow;
}

.btn-gk-primary:hover,
.btn-gk-primary:focus,
.btn-gk-primary:focus-visible,
.btn-gk-primary:active {
  color: $gk-dark-green;
  background-color: $gk-dark-yellow;
  border-color: $gk-yellow;
  outline-color: black;
  outline-width: var(--bs-focus-ring-width);
}

.btn-gk-primary.disabled,
.btn-gk-primary:disabled {
  background-color: $gk-yellow;
  border-color: $gk-yellow;
  cursor: not-allowed;
}

/*** .btn-gk-warning ***/
.btn-gk-warning {
  color: $gk-white;
  background-color: $gk-light-green;
  border-color: $gk-light-green;
}

.btn.btn-gk-warning:hover,
.btn.btn-gk-warning:focus,
.btn.btn-gk-warning:focus-visible,
.btn.btn-gk-warning:active {
  color: $gk-white;
  background-color: $gk-green;
  border-color: $gk-green;
}

.btn-gk-warning.disabled,
.btn-gk-warning:disabled {
  background-color: $gk-light-green;
  border-color: $gk-light-green;
  cursor: not-allowed;
}

/*** .btn-xlarge ***/
.btn.btn-xlarge {
  font-size: 1.4rem;
  letter-spacing: -0.01em;
  line-height: normal;
  padding: .75rem 1rem;
  white-space: normal;
  width: 100%;
}

/*** .btn-gk-danger ***/
.btn-gk-danger {
  color: $gk-white;
  background-color: rgba($gk-red, 0.9);
  border-color: rgba($gk-red, 0.9);
}

.btn.btn-gk-danger:hover,
.btn.btn-gk-danger:focus,
.btn.btn-gk-danger:focus-visible,
.btn.btn-gk-danger:active {
  color: $gk-white;
  background-color: $gk-red;
  border-color: $gk-red;
}

.btn-gk-danger.disabled,
.btn-gk-danger:disabled {
  background-color: rgba($gk-red, 0.5);
  border-color: rgba($gk-red, 0.5);
  cursor: not-allowed;
}

/*** .btn-error-msg  ***/
.btn-error-msg {
  color: $gk-red;
  background-color: rgba($gk-red, 0.3);
  border-color: rgba($gk-red, 0.3);
  width: 100%;
  padding: 10px 10px;
  font-size: 1.8rem;
  white-space: normal;
  line-height: normal;
}

.btn.btn-error-msg:hover,
.btn.btn-error-msg:focus,
.btn.btn-error-msg:focus-visible,
.btn.btn-error-msg:active {
  color: $gk-red;
  background-color: rgba($gk-red, 0.3);
  border-color: rgba($gk-red, 0.3);
  font-size: 14px;
}

.btn-error-msg.disabled,
.btn-error-msg:disabled {
  background-color: rgba($gk-red, 0.5);
  border-color: rgba($gk-red, 0.5);
  cursor: not-allowed;
}

.btn-light {
  background-color: #fff;
  border-color: #ccc;
}
.btn-light:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}

/* When an icon is the first direct descendant of a button, give it ending margin */
.btn > *:first-child.bi {
  margin-inline-end: .35rem;
}
/* When an icon is the last direct descendant of a button, give it starting margin */
.btn > *:last-child.bi {
  margin-inline-start: .35rem;
}
