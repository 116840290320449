html, body, .map {
  margin: 0;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
}

a,
a:link,
a:visited {
  --bs-link-color: #337ab7;
  --bs-link-color-rgb: to-rgb(#337ab7);
  color: var(--bs-link-color);
}
a:hover,
a:active {
  --bs-link-color: #23527c;
  --bs-link-color-rgb: to-rgb(#23527c);
  color: var(--bs-link-color);
}

/* Offset focus outline on certain elements */
a:focus,
a:focus-visible,
img:focus,
img:focus-visible,
span:has(> i.bi):focus,
span:has(> i.bi):focus-visible {
  outline-offset: 3px;
}


h1, h2, h3, h4, h5, h6 {
  font-kerning: normal;
  font-weight: 400;
  letter-spacing: -0.01em;
}

p:last-child {
  margin-bottom: 0;
}
