.gk-loader {
  border: 10px solid #f3f3f3;
  border-radius: 50%;
  border-top: 10px solid $gk-green;
  width: 50px;
  height: 50px;
  -webkit-animation: gk-loader_spin 2s linear infinite; /* Safari */
  animation: gk-loader_spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes gk-loader_spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes gk-loader_spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
